<template>
  <v-col>
    <v-col v-if="$vuetify.breakpoint.mdAndUp" class="pt-0 pb-2">
      <v-btn-toggle v-model="tab" class="transparent" mandatory>
        <v-btn v-for="(item, idx) in items" :key="idx"
          class="ma-0"
          active-class="rouge--text text--rouge v-btn--active"
          :value="idx"
          text
          outlined
          style="width: 351px; height: 50px">
          {{ item.tab }}
        </v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col class="content-page mt-2 pt-0">
      <v-row v-for="(item, idx) in items" :key="idx">
        <component v-if="tab === idx" :is="item.content"></component>
      </v-row>
    </v-col>
  </v-col>
</template>

<script>
  import userMixin from '@/components/shared/userMixin'
  import companyMixin from '@/components/shared/companyMixin'
  import Payment from "@/components/company/charge/Payment";
  import History from "@/components/company/charge/History";

  export default {
    name: "CompanyCharge",
    components: {Payment, History},
    mixins: [userMixin, companyMixin],
    data() {
      return {
        tab: 0,
        items: [
          {tab: '결제하기', content: () => import('@/components/company/charge/Payment')},
          {tab: '결제 내역', content: () => import('@/components/company/charge/History')}
        ]
      }
    },
    watch: {
    },
    methods: {
    },
    created() {

    }
  }
</script>

<style scoped lang="scss">
::v-deep .v-list .title .v-list-item {
  padding: 0px;
  border-top: 1px solid #757575;
  border-top: 1px solid var(--warm-grey-color);
  border-bottom: 1px solid #757575;
  border-bottom: 1px solid var(--warm-grey-color);
  font-size: 14px;
}

::v-deep .v-list .v-list-item {
  padding: 0px;
  border-bottom: 1px solid #d3d3d3;
  font-size: 14px;
}

::v-deep .estimate {
  border-radius: 1px;
  background-color: #fcf2f3;
  padding: 10px;
}

::v-deep .light_bulb {
  width: 14px;
  height: 20px;
  object-fit: contain;
  background-color: #00000000;
}

::v-deep .v-text-field--outline.v-text-field--single-line input {
  margin-top: 0;
}

::v-deep .v-text-field--outline.v-text-field--single-line .v-input__slot {
  min-height: 40px;
  border-radius: 4px;
  border: solid 1px #c2c2c2;
  border: solid 1px var(--pinkish-grey-color);
}

::v-deep .card td[colspan='5'] {
  color: var(--rouge-color);
}

::v-deep .card td[colspan='5']::before {
  font-family: 'Material Design Icons'; //"Font Awesome 5 Free"
  content: '\f5d6'; //"\f06a"
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
}
</style>