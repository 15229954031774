<template>
  <v-col>
    <v-card class="pa-6" flat>
      <v-row class="subheading">충전 내역</v-row>
        <v-data-table
            :headers="headers"
            :items="companyPoints"
            item-key="id"
            class="mt-3"
            disable-sort
            style="backgroud-color: transparent;">
          <template v-slot:item.createdAt="{ item }">
            {{ item.createdAt.split('T')[0] }}
          </template>
          <template v-slot:item.taggableType="{ item }">
            {{ (item.amount < 0 && item.taggableType == 'proposition') ? {proposition: '환불', charge: ''}[item.taggableType] : {proposition: '소진', charge: '충전'}[item.taggableType] }}
          </template>
          <template v-slot:item.charge="{ item }">
            <div v-if="item.taggableType == 'charge'">
              ₩{{item.amount | numFormat}}
            </div>
            <div v-else>
              -
            </div>
          </template>
          <template v-slot:item.use="{ item }">
            <div v-if="item.taggableType == 'proposition' && item.amount > 0">
              ₩{{item.amount | numFormat}}
            </div>
            <div v-else>
              -
            </div>
          </template>
          <template v-slot:item.point="{ item }">
            <div v-if="item.taggableType == 'proposition' && item.amount > 0 && item.proposition">
              ₩{{item.proposition.point | numFormat}}
            </div>
            <div v-else>
              -
            </div>
          </template>
          <template v-slot:item.pr="{ item }">
            <div v-if="item.taggableType == 'proposition' && item.amount > 0 && item.proposition">
              ₩{{(item.amount - item.proposition.point) | numFormat}}
            </div>
            <div v-else>
              -
            </div>
          </template>
          <template v-slot:item.refund="{ item }">
            <div v-if="item.amount < 0 && item.taggableType == 'proposition'">
              ₩{{Math.abs(item.amount) | numFormat}}
            </div>
            <div v-else>
              -
            </div>
          </template>
          <template v-slot:item.balance="{ item }">
            ₩{{ item.balance | numFormat}}
          </template>
        </v-data-table>
    </v-card>
  </v-col>
</template>

<script>
  import userMixin from '@/components/shared/userMixin'
  import campaignMixin from '@/components/shared/campaignMixin'

  export default {
    name: "History",
    mixins: [userMixin, campaignMixin],
    data() {
      return {
        headers: [
          { text: '일시', value: 'createdAt' },
          { text: '유형', value: 'taggableType' },
          { text: '충전액', value: 'charge' },
          { text: '소진액', value: 'use' },
          { text: '환불', value: 'refund' },
          { text: '잔액', value: 'balance' }
        ],
        companyPoints: [],
        propositions: []
      }
    },
    async mounted() {
      if (this.campaigns.length > 0) {
        let query = '';
        this.campaigns.map((campaign, idx) => {
          query += 'campaignId=' + campaign.id;
          if (idx !== this.campaigns.length - 1) {
            query += ' OR '
          }
        });
        let propositions = await this.axios.get('/campaign/proposition?query=' + encodeURIComponent(query));
        this.propositions = propositions.data.propositions
      }

      let companyPoints = await this.axios.get(`company/user/point?companyId=${this.user.id}&orderBy=id&direction=desc`);
      this.companyPoints = companyPoints.data.companyPoints;

      this.companyPoints = this.companyPoints.map((companyPoint) => {
        let proposition = this._.find(this.propositions, {id: companyPoint.taggableId});
        if (proposition) {
          companyPoint.proposition = proposition;
        }
        return companyPoint;
      });
    }
  };
</script>
